import React from "react";
import { navigate } from "gatsby";

import Accordion from "src/components/accordion";
import Icon from "src/components/icon";
import ImageV2 from "src/components/image/image.v2";
import { getSlug } from "src/utils/slugs";

import languageTranslations from "src/translations/language";
import languages from "src/../src-dynamic/destination.languages";

type LanguagePickerProps = {
    labelClassName: string;
    itemsClassName: string;

    language: string;
    languages: Array<{code: string; name: string; icon: null | string}>;
    page_translations: TTranslations<{slug: string;}>;

    language_selector_on_navigation_bar: "static" | "icon" | "acronym" | "full-name" | "icon+acronym" | "icon+full-name";
    language_selector_on_dropdown: "icon" | "acronym" | "full-name" | "icon+acronym" | "icon+full-name";    
    language_selector_icon: TImage | undefined | null;
    language_flag_style: "squared" | "round-corners" | "rounded";
    language_selector_dropdown_chevron_icon: boolean;
    
    modalInTop?: boolean;

    onChange?: (newLanguage: string) => void;
    onToggleDropdown?: (open: boolean) => void;
}

export default class LanguagePicker extends React.Component<LanguagePickerProps> {
    
    static getLanguageCode(language: string = "") {
        return language.split("-")[0].toUpperCase();
    }

    static getLanguageName(language: string, languageToShow: string) {
        const code = LanguagePicker.getLanguageCode(language);
        const source = LanguagePicker.getLanguageCode(languageToShow);
        const foundl = languages.languages.find(lang => LanguagePicker.getLanguageCode(lang.code) === code);
        if (foundl && foundl.name) return foundl.name;
        const translations = languageTranslations[source] || languageTranslations["EN"];
        return translations[code];
    }

    static getIconForLanguage(language: string, languages: Array<{code: string; name: string; icon: null | string}>) {
        const found = languages.find(lang => lang.code === language);
        if (found && found.icon) return found.icon;
        return undefined;
    }

    private accordionRef = React.createRef<Accordion>();

    public get flagRoundingStyle() {
        if (this.props.language_flag_style === 'rounded') return 'rounded-full';
        if (this.props.language_flag_style === 'round-corners') return 'rounded-md';
        return '';
    }

    public get iconShown() {
        const iconFile = LanguagePicker.getIconForLanguage(this.props.language, this.props.languages);
        switch (this.props.language_selector_on_navigation_bar) {
            case "static": return <ImageV2 className={`h-6 w-6 ${this.flagRoundingStyle}`} src={this.props.language_selector_icon?.imageFile} />
            case "acronym": return null;
            case "full-name": return null;
            case "icon":
            case "icon+acronym":
            case "icon+full-name": return <ImageV2 className={`h-6 w-6 ${this.flagRoundingStyle}`} src={iconFile} />
            default: return null;
        }
    }

    public pickLanguage(newLanguage: string) {
        if (this.props.onChange) this.props.onChange(newLanguage);
        const slug = getSlug(newLanguage, newLanguage, this.props.page_translations);
        if (slug) navigate(slug);
        this.accordionRef.current?.close();
    }

    public get languages() {
        return this.props.page_translations.map(translation => translation.language);
    }

    public get label() {
        switch (this.props.language_selector_on_navigation_bar) {
            case "static": return null;
            case "acronym": return LanguagePicker.getLanguageCode(this.props.language);
            case "full-name": return LanguagePicker.getLanguageName(this.props.language, this.props.language);
            case "icon": return null;
            case "icon+acronym": return LanguagePicker.getLanguageCode(this.props.language);
            case "icon+full-name": return LanguagePicker.getLanguageName(this.props.language, this.props.language);
            default: return null;
        }
    }

    public get chevron() {
        if (this.props.language_selector_dropdown_chevron_icon === false) return null;

        const chevronRotation = this.props.modalInTop
            ? (this.isOpen ? '' : 'rotate-180')
            : (this.isOpen ? 'rotate-180' : '');

        return <Icon className={`h-4 w-4 ${chevronRotation}`} icon="ChevronDown" />;
    }

    public get isOpen() {
        return this.accordionRef.current?.state.open || false;
    }

    public open() {
        if (this.accordionRef.current) this.accordionRef.current.open();
    }

    public close() {
        if (this.accordionRef.current) this.accordionRef.current.close();
    }

    public renderDropdownItem(language_code: string) {
        const iconFile = LanguagePicker.getIconForLanguage(language_code, this.props.languages);
        const acronym = LanguagePicker.getLanguageCode(language_code);
        const fullname = LanguagePicker.getLanguageName(language_code, this.props.language);
        const selected = language_code === this.props.language;
        const selectedClass = selected ? '!font-bold text-[color:var(--dropdown-active-color)]' : '';
        let icon = null;
        let label = null;
        let extraClasses = '';
        if (this.props.language_selector_on_dropdown === 'acronym') { label = acronym; }
        if (this.props.language_selector_on_dropdown === 'full-name') { label = fullname; }
        if (this.props.language_selector_on_dropdown === 'icon') { icon = <ImageV2 className={`h-4 w-4 ${this.flagRoundingStyle}`} src={iconFile} />; extraClasses = 'min-w-[16px]'; }
        if (this.props.language_selector_on_dropdown === 'icon+acronym') { icon = <ImageV2 className={`h-4 w-4 mr-2 ${this.flagRoundingStyle}`} src={iconFile} />; label = acronym; extraClasses = 'min-w-[45px]'; }
        if (this.props.language_selector_on_dropdown === 'icon+full-name') { icon = <ImageV2 className={`h-4 w-4 mr-2 ${this.flagRoundingStyle}`} src={iconFile} />; label = fullname; extraClasses = "mx-4 ml-0"; }

        return <div key={`lang${language_code}`} onClick={(ev) => this.pickLanguage(language_code)} className={`${extraClasses} flex flex-row items-center cursor-pointer language-picker-item text-[color:var(--dropdown-text-color)] hover:text-[color:var(--dropdown-hover-color)] ${this.props.itemsClassName || ""} ${selectedClass}`}>
            {icon} <span>{label}</span>
        </div>
    }

    onToggleDropdown(open: boolean) {
        if (this.props.onToggleDropdown) this.props.onToggleDropdown(open);
        this.forceUpdate();
    }

  
    render() {
        const modalPosition = this.props.modalInTop === true ? 'bottom-6 left-0': 'top-6 right-0';
        return <>
        <div className="relative">
            <Accordion
                ref={this.accordionRef}
                removeChildrenWhenClosed={true}
                opener={<div className={`flex flex-row gap-2 items-center language-picker-dropdown cursor-pointer text-[color:var(--text-color)] hover:text-[color:var(--hover-color)] ${this.props.labelClassName || ""}`}>{this.iconShown} {this.label} {this.chevron}</div>}
                closeOther={true}
                onToggle={(open) => this.onToggleDropdown(open)}
            >
                <div className={`absolute ${modalPosition} z-50 rounded-lg shadow-md p-4 flex flex-col gap-4 bg-[color:var(--dropdown-background)] border border-[color:var(--dropdown-outline)]`}>
                    {this.languages.map((code) => this.renderDropdownItem(code))}
                </div>
            </Accordion>
        </div>
        </>
    }
}