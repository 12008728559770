import React from "react";
import { type PageProps } from "gatsby";

import ApplicationContextProvider from "src/context";

import CookiesManager from "src/components/cookiesManager";
import Profiler from "src/components/profiler/profiler.v1";
import Styling from "src/layouts/components/styling/styling";

import { isSafari } from "src/utils/device";
import { addListener, removeListener } from "src/utils/events";
import { getCurrentUri } from "src/utils/history";
import { goToAnchor } from "src/utils/layout";
import { restoreScrollPosition, storeScrollPosition } from "src/utils/scroll";

type LayoutProps = PageProps<undefined, {
  layout: TLayout;
  language: string;
  defaultLanguage: string;
  defaultCurrency: string;
} & CommonPageContextProps>;


export class LayoutClass extends React.Component<LayoutProps> {

  private identifier = (() => {
    const { pageContext } = this.props;
    const identifier = pageContext?.layout
      ? typeof pageContext.layout === 'string'
        ? pageContext.layout
        : pageContext.layout.identifier
      : 'empty';
    return identifier;
  })();

  ref: React.RefObject<HTMLElement> = React.createRef();

  componentDidMount() {
    if (!restoreScrollPosition(getCurrentUri())) setTimeout(goToAnchor, 300);
    addListener('scroll', this.storeScrollPosition);
    const safari = isSafari() ? 'safari-browser' : 'other';
    if (this.ref.current){
      this.ref.current.className += " " + safari;
    }
  }

  storeScrollPosition = () => {
    storeScrollPosition(getCurrentUri());
  }

  componentDidUpdate(prevProps: LayoutProps) {
    if (prevProps.uri !== this.props.uri) {
      if (!restoreScrollPosition(getCurrentUri())) goToAnchor();
    }
  }

  componentWillUnmount() {
    removeListener('scroll', this.storeScrollPosition);
  }

  render() {
    const { children, pageContext } = this.props;
    const layout = this.props.pageContext?.layout;
    const cookies = pageContext.cookies || {};

    return (
      <Profiler id="root">
      <ApplicationContextProvider
        language={pageContext.language}
        defaultLanguage={pageContext.defaultLanguage}
        defaultCurrency={pageContext.defaultCurrency}
        destinationId={pageContext.destinationId}
        isInApp={pageContext.isInApp}
      >
        <Profiler id="styling">
          {layout ? <Styling layout={layout} className="mainapp" /> : null}
        </Profiler>
        <main ref={this.ref} className={[this.identifier, 'mainapp'].join(' ')} lang={pageContext.language}>
          <Profiler id="root-children">
            {children}
          </Profiler>
        </main>
        <Profiler id="cookies-manager">
          <CookiesManager
            language={pageContext.language}
            cookieYesId={cookies.cookieYes}
            cookieInfo={false && this.identifier === 'gausta'}
          />
        </Profiler>
      </ApplicationContextProvider>
      </Profiler>
    );
  }
}

export default LayoutClass;
