import React, { useState, useEffect} from "react";

import Icon from "../icon";

import { getSession } from "../../utils/localStorage";
import { isRunTime } from "../../utils/isRunTime";

const Cart = ({
  badgeBorderColor = "#fff",
  badgeBackgroundColor = "#f6ca1c",
  badgeColor = "#242426",
  iconColor = '#242526'
}) => {
  const [totalProducts, setTotalProducts] = useState(0);
  
  useEffect(() => {
    rerenderCart();
  }, [])

  useEffect(() => {
    if (isRunTime()) {
      window.addEventListener("setSession", rerenderCart);
      return () => {
        window.removeEventListener("setSession", rerenderCart);
      };
    }
  });

  const rerenderCart = () => {
    const session = getSession();
    let quantity = 0;
    if(session && session.products && Array.isArray(session.products)) {
      session.products.forEach(prod => {
        if (prod.quantity) quantity += prod.quantity;
        if (prod.bundleProducts && Array.isArray(prod.bundleProducts)) {
          prod.bundleProducts.forEach(bundle => {
            if (bundle.quantity) quantity += bundle.quantity
          })
        }
      })
      setTotalProducts(quantity);
    }
  };

  return (
    <div className="relative h-8 flex items-center">
      <Icon icon="Basket" className="block h-6 w-6 stroke-cyan-200"  />
      {
        totalProducts && totalProducts > 0
        ?
        <div
          className={`absolute text-xs p-1 rounded-full border-2 w-4 h-4 top-[-4px] right-0 flex items-center justify-center`}
          style={{ borderColor: badgeBorderColor, backgroundColor: badgeBackgroundColor, color: badgeColor }}
        >
          {totalProducts}
        </div>
        : null
      }
    </div>
  );
}

export default Cart;
