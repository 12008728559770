import React from "react";

export default function Trash(props: any) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66797 4.9H3.85315M3.85315 4.9H13.3346M3.85315 4.9V13.3C3.85315 13.9627 4.38378 14.5 5.03834 14.5H10.9643C11.6188 14.5 12.1494 13.9627 12.1494 13.3V4.9M5.63093 4.9V3.7C5.63093 3.03726 6.16156 2.5 6.81612 2.5H9.18649C9.84105 2.5 10.3717 3.03726 10.3717 3.7V4.9"
        stroke="#75757D"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}